import React, { ReactElement } from 'react'

import { useAmplitude } from '../../lib/tracking'
import { Noop } from '../noop'

/**
 * Component containing everything that needs to be added in the `document.head`
 * for every page.
 */
function Head(): ReactElement {
  useAmplitude()
  return <Noop />
}

export { Head }
