/* eslint-disable import/no-default-export */

import React, { ComponentType, ErrorInfo, ReactElement } from 'react'
import App, { AppContext, AppInitialProps, createUrl } from 'next/app'

import { MyAppInitialProps, MyAppState } from '../@types/_app.types'
import { initializeSentry } from '../lib/vendor/sentry'
import { Head } from '../components/head'

const { captureException, showReportDialog } = initializeSentry()

class MyApp extends App {
  public state = {
    errorEventId: undefined,
    hasError: false,
  }

  public static async getInitialProps({
    Component,
    ctx,
  }: AppContext): Promise<MyAppInitialProps> {
    try {
      let pageProps = {}

      if (Component.getInitialProps) {
        pageProps = await Component.getInitialProps(ctx)
      }

      return { pageProps }
    } catch (error) {
      // Capture errors that happen during a page's getInitialProps.
      // This will work on both client and server sides.
      const errorEventId = await captureException(error, ctx)
      return {
        errorEventId,
        hasError: true,
        pageProps: {},
      }
    }
  }

  public static getDerivedStateFromProps(
    props: MyAppInitialProps,
    state: MyAppState
  ): MyAppState {
    // If there was an error generated within getInitialProps, and we haven't
    // yet seen an error, we add it to this.state here
    return {
      errorEventId: props.errorEventId || state.errorEventId || undefined,
      hasError: props.hasError || state.hasError || false,
    }
  }

  public static getDerivedStateFromError(): { hasError: boolean } {
    // React Error Boundary here allows us to set state flagging the error (and
    // later render a fallback UI).
    return { hasError: true }
  }

  public async componentDidCatch(
    error: Error,
    errorInfo: ErrorInfo
  ): Promise<void> {
    const errorEventId = await captureException(error, undefined, errorInfo)

    // Store the event id at this point as we don't have access to it within
    // `getDerivedStateFromError`.
    this.setState({ errorEventId })
  }

  public render(): ReactElement {
    const { router, Component, pageProps } = this.props
    const Content = this.getContent({
      Component,
      pageProps: {
        ...pageProps,
        url: createUrl(router),
      },
    })

    return (
      <>
        <Head />
        {Content}
      </>
    )
  }

  protected getContent({
    Component,
    pageProps,
  }: AppInitialProps & { Component: ComponentType }): ReactElement {
    return this.state.hasError ? (
      <section>
        <h1>There was an error!</h1>
        <p>
          <button onClick={this.handleReport} type="button">
            Report this error
          </button>
        </p>
        <p>
          <button onClick={this.handleReload} type="button">
            Or, try reloading the page
          </button>
        </p>
      </section>
    ) : (
      <Component {...pageProps} />
    )
  }

  protected handleReport = (): void => {
    /* eslint-disable-next-line no-invalid-this */
    showReportDialog({ eventId: this.state.errorEventId })
  }

  protected handleReload = (): void => {
    window.location.reload(true)
  }
}

export default MyApp
