/* eslint-disable global-require */
/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable @typescript-eslint/no-require-imports */

import { Debug } from '@sentry/integrations'
// NOTE: This import will be replaced with `@sentry/browser`
// client side thanks to the webpack config in next.config.js
import { init } from '@sentry/node'
import { ErrorInfo } from 'react'
import { TestkitResult } from 'sentry-testkit'

import { PageContext, SentryError, SentryOptions } from './@types'
import { captureException } from './capture-exception'
import { showReportDialog } from './show-reporting-dialog'

/**
 * Initliaze Sentry.
 *
 * @param release - Release identifier.
 */
function initializeSentry(
  release = process.env.SENTRY_RELEASE
): {
  showReportDialog: ({ eventId }: { eventId?: string }) => void
  captureException: (
    err: SentryError,
    ctx?: PageContext,
    errorInfo?: ErrorInfo
  ) => Promise<string>
} {
  const sentryOptions: SentryOptions = {
    attachStacktrace: true,
    dsn: process.env.SENTRY_DSN,
    environment: process.env.ENVIRONMENT,
    maxBreadcrumbs: 50,
    release,
    transport: undefined,
  }

  // When we're developing locally
  if (process.env.NODE_ENV !== 'production') {
    const sentryTestkit: () => TestkitResult = require('sentry-testkit')
    const { sentryTransport } = sentryTestkit()

    // Don't actually send the errors to Sentry
    sentryOptions.transport = sentryTransport

    // Instead, dump the errors to the console
    sentryOptions.integrations = [
      new Debug({
        // Trigger DevTools debugger instead of using console.log
        debugger: false,
      }),
    ]
  }

  init(sentryOptions)

  return {
    captureException,
    showReportDialog,
  }
}

export { initializeSentry }
