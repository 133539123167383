import {
  Scope,
  captureException as capture,
  configureScope,
  flush,
} from '@sentry/node'
import Cookie from 'js-cookie'
import { ErrorInfo } from 'react'

import { isServer } from '../../platform'

import { PageContext, SentryError } from './@types'

/**
 * Captures Sentry error.
 *
 * @param err       - Error object.
 * @param ctx       - Server context object.
 * @param errorInfo - Additional error information.
 */
async function captureException(
  err: SentryError,
  ctx?: PageContext,
  errorInfo?: ErrorInfo
): Promise<string> {
  configureScope((scope): void => {
    if (err.message) {
      // De-duplication currently doesn't work correctly for SSR / browser errors
      // so we force deduplication by error message if it is present
      scope.setFingerprint([err.message])
    }

    if (err.statusCode) {
      scope.setExtra('statusCode', err.statusCode)
    }

    if (ctx) {
      const { req, res, query, pathname } = ctx

      if (res && res.statusCode) {
        scope.setExtra('statusCode', res.statusCode)
      }

      if (isServer && req) {
        scope.setTag('ssr', 'true')
        scope.setExtra('url', req.url)
        scope.setExtra('method', req.method)
        scope.setExtra('headers', req.headers)
        scope.setExtra('params', req.params)
        scope.setExtra('query', req.query)

        // On server-side we take session cookie directly from request
        if (req.cookies && req.cookies.sid) {
          scope.setUser({ id: req.cookies.sid })
        }
      } else {
        scope.setTag('ssr', 'false')
        scope.setExtra('query', query)
        scope.setExtra('pathname', pathname)

        // On client-side we use js-cookie package to fetch it
        const sessionId = Cookie.get('sid')
        if (sessionId) {
          scope.setUser({ id: sessionId })
        }
      }
    }

    if (errorInfo) {
      Object.entries(errorInfo).forEach(
        ([key, value]): Scope => scope.setExtra(key, value)
      )
    }
  })

  if (isServer) {
    const errorEventId = capture(err)

    // Sentry buffers errors and this causes the lambda to wait at most 2
    // seconds while the buffer is flushed to Sentry's servers without this,
    // errors never reach Sentry as the lambda is killed before the buffer is
    // emptied.
    await flush(2000)

    return errorEventId
  }

  return capture(err)
}

export { captureException }
